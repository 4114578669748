export default [
    {
      name: "My-Application",
      path: "my-applications",
      props:true,
      component: () => import("./MyApplication.vue"),
      meta: {
        permissions: []
      }
    },
    
  ];