export default [
  {
    name:"Index-Request",
    path:"index-Request",
    component: ()=>import("./indexRequest.vue"),
    meta: {
      permissions: []
    }
  },
  {
    name: "Application-Request",
    path: "application-request/:year",
    component: () => import("./ApplicationCategories.vue"),
    props: true,
    meta: {
      permissions: []
    }
  },
  {
    name: 'Application Request By Category',
    path: 'application-request/:year/:category',
    component: () => import('./ApplicationRequest.vue'),
    props: true,
    meta: {
      permissions: []
    }
  },
  {
    name: "Application Approval Request",
    path: "application-request-approval/:requestUniqueId",
    component: () => import("./applicationRequestApproval.vue"),
    props: true,
    meta: {
      permissions: []
    }
  },
];
