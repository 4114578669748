import gql from "graphql-tag";
const data = `data {
    applicationFeedbackUniqueId
    applicationFeedbackInterviewDate
    applicationFeedbackDescription
    applicationFeedbackCategory
    applicationFeedbackCreatedDate
}`
const response = `response {
    status
    code
    message
    id
}`

export const  getApplicationFeedback = gql`query GetApplicationFeedback($applicationRequestUniqueId: String!) {
    getApplicationFeedback(applicationRequestUniqueId: $applicationRequestUniqueId) {
      ${response}
      ${data}
    }
}
`;
export const createApplicationFeedback = gql`mutation CreateApplicationFeedback($input: ApplicationFeedbackInputObject) {
    createApplicationFeedback(input: $input) {
        ${response}
        ${data}
    }
}`;
