import {getOnGoingResearchData , registerResearchProposal , updateResearchProposal , approveResearchProposal , deleteResearchProposal , disApproveResearchProposal , getMyResearchProposalData} from "@/graphql/ResearchProposal/ResearchProposal.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        AllResearchProposal: [],
        ResearchProposal: {},

    },
    getters: {
        getAllResearchProposal: (state) => state.AllResearchProposal,
        getResearchProposal: (state) => state.ResearchProposal,

    },
    actions: {
        async getMyResearchProposalData(context, filtering = {}) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .query({
                    fetchPolicy: "network-only",
                    query: getMyResearchProposalData,
                    variables: { filtering },
                })
                .then((output) => {
                    let { response, data } = output.data.getMyResearchProposalData;
                    if (response.status) {
                        if (filtering?.proposalUniqueId) {
                            context.commit("setStoreItem", {
                                module: "ResearchProposalManagement",
                                entity: "AllResearchProposal",
                                data:data,
                            });
                            return data;
                        } else {
                            context.commit("setStoreItem", {
                                module: "ResearchProposalManagement",
                                entity: "AllResearchProposal",
                                data: data,
                            });
                            return data;
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch");
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async getOnGoingResearchData(context, filtering = {}) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .query({
                    fetchPolicy: "network-only",
                    query: getOnGoingResearchData,
                    variables: { filtering },
                })
                .then((output) => {
                    let { response, data } = output.data.getOnGoingResearchData;
                    if (response.status) {
                        if (filtering?.proposalUniqueId) {
                            context.commit("setStoreItem", {
                                module: "ResearchProposalManagement",
                                entity: "AllResearchProposal",
                                data:data,
                            });
                            return data;
                        } else {
                            context.commit("setStoreItem", {
                                module: "ResearchProposalManagement",
                                entity: "AllResearchProposal",
                                data: data,
                            });
                            return data;
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch");
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async registerResearchProposal(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: registerResearchProposal,
                    variables: { input },
                })
                .then((output) => {
                    let { response, data } = output.data.registerResearchProposal;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResearchProposalManagement",
                            entity: "AllResearchProposal",
                            uniqueKey: "proposalUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async updateResearchProposal(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: updateResearchProposal,
                    variables: { input },
                })
                .then((output) => {
                    let { response, data } = output.data.updateResearchProposal;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResearchProposalManagement",
                            entity: "AllResearchProposal",
                            uniqueKey: "proposalUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async deleteResearchProposal(context, proposalUniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: deleteResearchProposal,
                    variables: { proposalUniqueId },
                })
                .then((output) => {
                    let { response, data } = output.data.deleteResearchProposal;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResearchProposalManagement",
                            entity: "AllResearchProposal",
                            uniqueKey: "proposalUniqueId",
                            data,
                            remove: true,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

        async approveResearchProposal(context, proposalUniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: approveResearchProposal,
                    variables: { proposalUniqueId },
                })
                .then((output) => {
                    let { response, data } = output.data.approveResearchProposal;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResearchProposalManagement",
                            entity: "AllResearchProposal",
                            uniqueKey: "proposalUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

        async disApproveResearchProposal(context, proposalUniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: disApproveResearchProposal,
                    variables: { proposalUniqueId },
                })
                .then((output) => {
                    let { response, data } = output.data.disApproveResearchProposal;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResearchProposalManagement",
                            entity: "AllResearchProposal",
                            uniqueKey: "proposalUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
    },
};
