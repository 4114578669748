

export default [
  {
    path: "/projects",
    name: "Projects Main",
    component: () =>
      import("@/components/project_management/ProjectManagementMain.vue"),
    meta: {
      permissions: [],
    },
    children: [
      {
        path: "index",
        name: "Project Index",
        component: () => import("./Index.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "project-list/:domainUniqueId",
        name: "Project List",
        component: () =>
          import("@/components/project_management/projects/Projects.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "portfolio-list",
        name: "Portfolio List",
        component: () =>
          import("@/components/project_management/PortfolioList.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "project-component-list/project/:componentUniqueId",
        name: "Project View",
        component: () =>
          import("@/components/project_management/projects/PorjectView.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "project-component-list/:projectUniqueId",
        name: "Project Component List",
        component: () =>
          import(
            "@/components/project_management/projects/project_components/ProjectComponents.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "project-folders/:projectUniqueId",
        name: "Project Folder List",
        component: () =>
          import(
            "@/components/project_management/projects/project_folders/ProjectFolders.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "project-folders/project-folder/:folderUniqueId",
        name: "Project Folder",
        component: () =>
          import(
            "@/components/project_management/projects/project_folders/ProjectFolderView.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "project-folders/project-folder/project-subFolder/:folderUniqueId",
        name: "Project subFolder",
        component: () =>
          import(
            "@/components/project_management/projects/project_folders/ProjectSubFolderView.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "task-list/:projectUniqueId",
        name: "Task List",
        component: () =>
          import(
            "@/components/project_management/projects/project_task/ProjectTasks.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "members/:projectUniqueId",
        name: "Project Members",
        component: () =>
          import(
            "@/components/project_management/projects/project_member/ProjectMembers.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "my-project",
        name: "My Project",
        component: () =>
          import(
            "@/components/project_management/projects/my_project/myProject.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "report/:projectUniqueId",
        name: "Report Project",
        component: () =>
          import(
            "@/components/project_management/weekly_report/weeklyReport.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "week/:projectUniqueId",
        name: "Weekly Project",
        component: () =>
          import(
            "@/components/project_management/weekly_report/myWeeklyReport.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      // Outside works
      {
        path: "outside-works",
        name: "Outside Works",
        component: () =>
          import(
            "@/components/project_management/outsideWork/OutsideWorks.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "pt-reports",
        name: "pt reports",
        component: () =>
            import(
                "@/components/project_management/ptProject/PtProjectList.vue"
                ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "outside-work/:outsideWorkUniqueId",
        name: "Outside Work",
        component: () =>
          import(
            "@/components/project_management/outsideWork/OutsideWorkView.vue"
          ),
        meta: {
          permissions: [],
        },
      },
    ],
  },
];