export default [{
    path: "/alumni-request",
    name: "alumni request",
    component: () => import("./AlumniRequestList.vue"),
    meta: {
        permissions: [],
    },

},
    {
        path: "/alumni-request-view/:uniqueId",
        name: "alumni Request view",
        component: () => import("./AlumniRequestView.vue"),
        meta: {
            permissions: [],
        },


    },
    {
        path: "/alumni-list",
        name: "alumni list",
        component: () => import("./AlumniList.vue"),
        meta: {
            permissions: [],
        },

    },
    {
        path: "/alumni-details/:uniqueId",
        name: "alumni details",
        component: () => import("./AlumniDetailsView.vue"),
        meta: {
            permissions: [],
        },

    },

];
