export default [
  {
    name: "Withdraw-Application",
    path: "withdraw-applications",
    props: true,
    component: () => import("./withdrawnApplication.vue"),
    meta: {
      permissions: [],
    },
  },
];
