

export default [
  {
    path: "/repositories",
    name: "Repository Main",
    component: () => import("@/components/repository/RepositoryMain.vue"),
    meta: {
      permissions: []
    },
    children: [
      {
        path: 'index',
        name: "Repository Index",
        component: () => import('./Index.vue'),
        meta: {
          permissions: []
        }
      },
      {
        path: 'meeting-minute-list',
        name: "Meeting Minute List",
        component: () => import("@/components/repository/meeting_minute/MeetingMinuteList.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "gallery-collection",
        name: "Gallery Collection",
        component: () => import("@/components/repository/gallery/Index.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "gallery-collections/:year",
        name: "Gallery Collection",
        component: () => import("@/components/repository/gallery/GalleryCollections.vue"),
        meta: {
          permissions: []
        },
        props: true
      },
      {
        path: "gallery-preview/:galleryUuid",
        name: "Gallery Preview",
        component: () => import("@/components/repository/gallery/GalleryPreview.vue"),
        meta: {
          permissions: []
        },
        props: true
      },
    ],
  }
];