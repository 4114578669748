export default [
  {
    path: "/reports",
    name: "Report Main",
    component: () => import("@/components/report_managements/ReportsMain.vue"),
    meta: {
      permissions: [],
    },
    children: [
      {
        path: "application-report",
        name: "Application Report",
        component: () =>
          import(
            "@/components/report_managements/application_category_report/ApplicationReport.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "students-reports",
        name: "Students Reports",
        component: () =>
          import(
            "@/components/report_managements/selected_students_report/SelectedStudentsReport.vue"
          ),
        meta: {
          permissions: [],
        },
      },

      {
        path: "institutional-reports",
        name: "Institutional Reports",
        component: () =>
          import(
            "@/components/report_managements/institutional_report/InstitutionalReport.vue"
          ),
        meta: {
          permissions: [],
        },
      },

      {
        path: "project-reports",
        name: "Project Reports",
        component: () =>
          import(
            "@/components/report_managements/project_report/projectReport.vue"
          ),
        meta: {
          permissions: [],
        },
      }

    ],
  },
];