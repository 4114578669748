
export default [
  {
    path: "/attendance",
    name: "Attendance",
    component: () => import("@/components/attendance/AttendanceMain.vue"),
    meta: {
      permissions: [],
    },
    children: [
      {
        path: "index",
        name: "Attendance Index",
        component: () => import("./Index.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "device-list",
        name: "Device List",
        component: () => import("@/components/attendance/devices/Devices.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "enrollment",
        name: "Enrollment",
        component: () =>
          import("@/components/attendance/enrollment/Enrollments.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "attendance-list",
        name: "Attendance List",
        component: () =>
          import("@/components/attendance/attendance/AttendanceList.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "Attendance-Dashboard",
        name: "Attendance Dashboard",
        component: () =>
          import("@/components/attendance/attendance/AttendanceDashboard.vue"),
        meta: {
          permissions: [],
        },


      },
      {
        path: "Attendance-view-categories",
        name: "Attendance categories",
        component: () =>
            import("@/components/attendance/attendance/AttandanceCards.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "Attendance-view-pt",
        name: "Attendance PT",
        component: () =>
            import("@/components/attendance/attendance/PtAttendanceList.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "Attendance-view-staff",
        name: "Attendance Staff",
        component: () =>
            import("@/components/attendance/attendance/StaffAttendanceList.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "Attendance-view-intern",
        name: "Attendance intern",
        component: () =>
            import("@/components/attendance/attendance/VolunterAttendanceList.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "Attendance-view-student",
        name: "Attendance student",
        component: () =>
            import("@/components/attendance/attendance/StudentAttendanceList.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "not-attended",
        name: "Not Attended",
        component: () =>
            import("@/components/attendance/attendance/NotAttended.vue"),
        meta: {
          permissions: [],
        },
      },
    ],
  },
];
