import gql from "graphql-tag";


const  response = `
response{
id
status
code
message
}
`

export const  researchProposaldata = `
data{
      id
      proposalUniqueId
      proposalTitle
      proposalDescription
      proposalArea {
        id
        researchAreaUniqueId
        researchAreaName
        researchAreaPhoto
        researchAreaDescription
        researchAreaIsActive
        researchAreaCreateddate
        researchAreaCreatedBy {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
          role
          dateJoined
        }
      }
      proposalStage
      proposalStatus
      researchProposalDoc
      proposalAuthor {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
      proposalAuthorInstitution {
        institutionUniqueId
        institutionName
        institutionType
        institutionCreatedDate
      }
      proposalAuthorCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      proposalIsActive
      proposalCreatedDate
      proposalAttachments {
        id
        proposalAttachmentUniqueId
        proposalAttachmentName
        proposalAttachmentPath
        proposalAttachmentFileType
        proposal
        proposalAttachmentIsActive
        proposalAttachmentIsDownloaded
        proposalAttachmentCreatedDate
      }
    }`

const  page = `
page{
number
hasNextPage
hasPreviousPage
nextPageNumber
previousPageNumber
}
`



export  const  registerResearchProposal = gql`
mutation RegisterResearchProposal($input: ResearchProposalInputObject) {
  registerResearchProposal(input: $input){
    ${response}
    ${researchProposaldata} 
  }
}
 `

export  const  updateResearchProposal = gql`
mutation UpdateResearchProposal($input: ResearchProposalInputObject) {
  updateResearchProposal(input: $input) {
    ${response}
    ${researchProposaldata} 
  }
}
`

export  const  deleteResearchProposal = gql`
mutation DeleteResearchProposal($proposalUniqueId: String!) {
  deleteResearchProposal(proposalUniqueId: $proposalUniqueId) {
    ${response}
    ${researchProposaldata} 
  }
}
`

export  const  approveResearchProposal = gql`
mutation ApproveResearchProposal($proposalUniqueId: String!) {
  approveResearchProposal(proposalUniqueId: $proposalUniqueId) {
    ${response}
    ${researchProposaldata}
  }
}
`

export  const  disApproveResearchProposal = gql`
mutation DisApproveResearchProposal($proposalUniqueId: String!) {
  disApproveResearchProposal(proposalUniqueId: $proposalUniqueId) {
    ${response}
    ${researchProposaldata}
  }
}
`

export  const  getOnGoingResearchData = gql`
query GetOnGoingResearchData($filtering: ResearchProposalFilteringbject) {
  getOnGoingResearchData(filtering: $filtering) {
    ${response} 
    ${researchProposaldata}
    ${page}
  }
}
`

export  const  getMyResearchProposalData = gql`
query GetMyResearchProposalData {
  getMyResearchProposalData {
    ${response}
    ${researchProposaldata} 
    ${page} 
  }
}
`
